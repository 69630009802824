import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/genresResource'

export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle
    }

    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexGenres'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(MoodID) {
        const response = await axios.get(url('showGenres', { genre: MoodID }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async store(data) {
        const response = await axios.post(url('storeGenres'), data)
        if (response.status === 201) {
            return getJson(response.data)
        }
    }

    async update(id, data) {
        // const json = setData(data)
        const response = await axios.put(
            url('updateGenres', { genre: id }),
            data,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async updateSort(playlistId) {
        const response = await axios.put(
            url('updateSortMoods', { mood: playlistId }),
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(id) {
        const response = await axios.delete(url('deleteGenres', { genre: id }))
        if (response.status === 200) {
            return true
        }
    }

    async attach(moodId, musicId) {
        const json = {music_id:musicId};
        const response = await axios.post(
            url('attachMoods', { mood: moodId }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async detach(moodId, musicId) {
        const json = {music_id:musicId};
        const response = await axios.post(
            url('detachMoods', { mood: moodId }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }
}
